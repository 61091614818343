<script>
import '@/utils'
import { stripKeyFromIdentifier, formatDuration } from '@/utils';
import { parse } from 'tinyduration'
import InventoryPreview from '@/components/player/inventory/InventoryPreview'
import PlayerLink from '@/components/player/PlayerLink.vue';

export default {
  components: {
    InventoryPreview,
    PlayerLink
  },
  props: {
    entries: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      avatarEndpoint: 'https://crafatar.com/renders/head/'
    }
  },
  created() {
    this.entries.forEach(entry => {
      let character =
        entry.data.characters.all
          .filter(char => char.uniqueId === entry.character);

      if (character.length > 0)
        entry.character = character[0];
      else
        entry.character = undefined;
    });
  },
  methods: {
    stripKeyFromIdentifier, formatDuration, parse,
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
}
</script>
<template>
  <table class="table-fixed w-full mt-5 m-auto text-center">
    <thead class="font-extrabold text-lg">
      <tr>
        <th class="w-1/12" />
        <th class="w-3/12">
          Player
        </th>
        <th class="w-1/12">
          Class
        </th>
        <th class="w-3/12">
          Items
        </th>
        <th class="w-1/12">
          Score
        </th>
        <th class="w-2/12">
          Playtime
        </th>
        <th class="w-1/12" />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(entry, index) in entries.filter(entry => entry.character !== undefined)"
        :key="entry"
      >
        <td class="py-2">
          #{{ index + 1 }}
        </td>
        <td class="py-2 text-left">
          <PlayerLink
            :player="entry.data"
            avatar="renders/head/"
          />
        </td>
        <td class="py-2">
          {{ capitalizeFirstLetter(stripKeyFromIdentifier(entry.character.type)) }}
        </td>
        <td class="py-2 text-left">
          <InventoryPreview :inventory="entry.character.inventory" />
        </td>
        <td class="py-2">
          {{ Math.round(new Number(entry.score)).toLocaleString() }}
        </td>
        <td class="py-2">
          {{ formatDuration(parse(entry.character.playTime)) }}
        </td>
        <td class="py-2">
          <router-link
            v-if="entry.character.previousLife != null"
            :to="`/oracle/player/${ entry.data.username }/${ entry.character.uniqueId }`"
          >
            🪦
          </router-link>
          <p v-else />
        </td>
      </tr>
    </tbody>
  </table>
</template>