<script>
import InventoryPreview from '../inventory/InventoryPreview.vue'
import '../../../utils.js'

export default {
  components: {
    InventoryPreview
  },
  props: {
    characters: {
      type: Object,
      required: true
    }
  }
}
</script>
<template>
  <table class="table-auto">
    <thead>
      <tr>
        <th>Class</th>
        <th>Level</th>
        <th>Items</th>
        <th>Fame</th>
        <th>Playtime</th>
        <th>Mode</th>
        <th />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="char in [...characters].sort((a, b) => b.fame - a.fame)"
        :key="char"
      >
        <td>
          {{ char.type.toLowerCase().capitalizeFirstLetter() }}
        </td>
        <td>
          {{ char.level }}
        </td>
        <td>
          <InventoryPreview :inventory="char.inventory" />
        </td>
        <td>
          {{ Number(char.fame).toLocaleString() }}
        </td>
        <td>
          {{ char.playTime }}
        </td>
        <td>
          <span class="has-tooltip">
            <img :src="`https://cdn.telosrealms.com/img/ruleset/${('placeholder' in char.ruleset) ? char.ruleset.type + '-' + char.ruleset.placeholder.replace('_', '-') : char.ruleset.type}.png`">
            <p class="dark:bg-gray-700 dark:text-gray-300 tooltip rounded shadow-lg p-2 m-2 bg-gray-100 -mt-20">
              {{ char.ruleset.type.split('-').map(function(i) { return i.toLowerCase().capitalizeFirstLetter() }).join(' ') }}
            </p>
          </span>
        </td>
        <td>
          <router-link
            v-if="char.previousLife != null"
            :to="`/oracle/player/${ $route.params.name }/${ char.uniqueId }`"
          >
            🪦
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<style scoped>
table {
  width: 100%;
}
</style>