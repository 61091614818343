class Leaderboard {
  constructor(name, id, type) {
    this.id = id;
    this.name = name;
    this.type = type;
  }
}

export const Type = {
  GUILD: 0,
  SOLO_CHARACTER: 1,
  GROUP_CHARACTER: 2
}

export const Leaderboards = {
  DEFAULT: new Leaderboard("Default", "default", Type.SOLO_CHARACTER),
  HARDCORE_SOLO: new Leaderboard("Solo Hardcore Ironman", "hardcore-ironman-solo", Type.SOLO_CHARACTER),
  HARDCORE_DUO: new Leaderboard("Duo Group Hardcore Ironman", "hardcore-ironman-duo", Type.GROUP_CHARACTER),
  HARDCORE_TRIO: new Leaderboard("Trio Group Hardcore Ironman", "hardcore-ironman-trio", Type.GROUP_CHARACTER),
  HARDCORE_SQUAD: new Leaderboard("Squad Group Hardcore Ironman", "hardcore-ironman-squad", Type.GROUP_CHARACTER),
  SOFTCORE: new Leaderboard("Softcore", "softcore", Type.SOLO_CHARACTER),
  GUILD: new Leaderboard("Guild", "guild", Type.GUILD)
}

export function getById(id) {
  return Object.values(Leaderboards).filter((it) => it.id == id)[0];
}