<script>
import '@/utils'

export default {
  props: {
    player: {
      type: Object,
      required: true
    },
    data: {
      default: () => [],
      type: Array,
      required: false
    }
  },
}
</script>
<template>
  <h1 class="object-cover p-3 rounded-lg text-center align-middle text-lg text-white font-extrabold dark:bg-amber-600 bg-indigo-800">
    <router-link
      :to="`/oracle/player/${ $route.params.name }/`"
      class="hover:underline"
    >
      {{ player.name }}
    </router-link>
    <span v-if="'character' in $route.params"> 
      {{
        ' /' +
          data.characters.all
            .filter(char => char.uniqueId === $route.params.character)
            .map(it => it.type.capitalizeFirstLetter())[0]
      }}'s Graveyard
    </span>
  </h1>
</template>