<script>
import '@/utils'
import { stripKeyFromIdentifier } from '@/utils';
import InventoryPreview from '@/components/player/inventory/InventoryPreview'
import PlayerLink from '@/components/player/PlayerLink.vue';

export default {
  components: {
    InventoryPreview,
    PlayerLink
  },
  props: {
    entries: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      avatarEndpoint: 'https://crafatar.com/renders/head/'
    }
  },
  created() {
    this.entries.forEach(entry => {
      // Filter out members that have deleted this group character
      entry.members = entry.members.filter(member => {
        return member.characters.all.filter(char => char.uniqueId == entry.character).length === 1
      });

      entry.members.forEach(member => {
        member.character =
          member.characters.all
            .filter(char => char.uniqueId === entry.character)[0];
      })
    });
  },
  methods: {
    stripKeyFromIdentifier,
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    filterEntries(entries) {
      return entries.filter((entry) => entry.members.length > 0 && entry.score > 0);
    }
  }
}
</script>
<template>
  <table
    v-if="entries.length > 0"
    class="table-fixed w-full mt-5 m-auto text-center border-separate"
  >
    <thead class="font-extrabold text-lg">
      <tr>
        <th class="w-3/12" />
        <th class="w-6/12">
          Members
        </th>
        <th class="w-3/12">
          Score
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(entry, index) in filterEntries(entries)"
        :key="entry.uniqueId"
      >
        <td>
          #{{ index + 1 }}
        </td>
        <td>
          <table class="table-fixed w-full text-center">
            <thead>
              <tr>
                <th class="w-3/12 py-2" />
                <th class="w-3/12 py-2" />
                <th class="w-3/12 py-2" />
                <th class="w-3/12 py-2" />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="member in entry.members.sort((a, b) => b.character.fame - a.character.fame)"
                :key="member.uniqueId"
              >
                <td class="text-left">
                  <PlayerLink
                    :player="member"
                    avatar="renders/head/"
                  />
                </td>
                <td>
                  <router-link
                    v-if="member.character.uniqueId.previousLife != null"
                    :to="`/oracle/player/${member.username}/${ member.character.uniqueId }`"
                    class="hover:underline"
                  >
                    {{ stripKeyFromIdentifier(member.character.type).capitalizeFirstLetter() }}
                  </router-link>
                  <div v-else>
                    {{ stripKeyFromIdentifier(member.character.type).capitalizeFirstLetter() }}
                  </div>
                </td>
                <td>
                  <InventoryPreview :inventory="member.character.inventory" />
                </td>
                <td>{{ new Number(member.character.fame).toLocaleString() }} fame</td>
              </tr>
            </tbody>
          </table>
        </td>
        <td>{{ Math.round(new Number(entry.score)).toLocaleString() }}</td>
      </tr>
    </tbody>
  </table>
</template>