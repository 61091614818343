class Glow {
    constructor(id, color) {
        this.id = id;
        this.color = color;
    }
}

export const Glows = {
    SUPPORTER: new Glow("realm:supporter", "#AA00AA"),
    MAX_MASTERY: new Glow("realm:max-mastery", "#FFFF55"),
    NORMAL_TOP_5: new Glow("realm:normal-top-5", "#55FF55"),
    NORMAL_TOP_1: new Glow("realm:normal-top-1", "#00AA00"),
    IRONMAN_TOP_5: new Glow("realm:ironman-top-5", "#FF5555"),
    IRONMAN_TOP_1: new Glow("realm:ironman-top-1", "#AA0000"),
    SOFTCORE_TOP_5: new Glow("realm:softcore-top-5", "#AAAAAA"),
    SOFTCORE_TOP_1: new Glow("realm:softcore-top-1", "#555555"),
    GROUP_IRONMAN_TOP_3: new Glow("realm:group-ironman-top-3", "#5555FF"),
    GROUP_IRONMAN_TOP_1: new Glow("realm:group-ironman-top-1", "#0000AA"),
    GUILD_TOP_3: new Glow("realm:guild-top-3", "#55FFFF"),
    GUILD_TOP_1: new Glow("realm:guild-top-1", "#00AAAA"),
    ALL_EXOTIC_PETS: new Glow("realm:all-exotic-pets", "#FFFFFF"),
    ALL_EXOTIC_MOUNTS: new Glow("realm:all-exotic-mounts", "#000000"),
}

export function getById(id) {
    return Object.values(Glows).filter((it) => it.id == id)[0];
  }