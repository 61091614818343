<template>
  <div class="flex flex-col items-center justify-center h-screen">
    <img
      src="../assets/logo-telos-realms.png"
      class="h-1/3 mx-auto"
    >
    <div class="inline-flex p-3 rounded-lg text-lg text-white font-extrabold dark:bg-amber-500 bg-indigo-800 mx-auto mt-16">
      <div class="px-3">
        play.telosrealms.com
      </div>
      <div class="border-l border-white mx-2" />
      <div
        v-if="serverStatus"
        class="px-3"
      >
        {{ serverStatus.players.online }}
      </div>
      <div
        v-else
        class="px-3"
      >
        0
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'Home',
  data() {
    return {
      serverStatus: undefined,
      serverStatusApi: "https://mc-api.co/v1/ping/play.telosrealms.com"
    }
  },
  created() {
    this.getServerData();
    document.title = 'Home | Telos Realms';
  },
  methods: {
    getServerData() {
      axios
        .get(this.serverStatusApi)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.serverStatus = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    }
  }
}
</script>