export default {
  getters: {
    darkMode: (state) => state.darkMode,
  },
  state: {
    darkMode: true,
  },
  mutations: {
    TOGGLE_DARKMODE(state, toggle) {
      state.darkMode = toggle;
    },
  },
  actions: {
    initTheme({ commit }) {
      //check store if the setting exists, if not default to false
      const darkMode = this.state.darkMode ? this.state.darkMode : false;
      //  `true` if the user has set theme to `dark` on browser/OS
      const userPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;
      if (darkMode || userPrefersDark) commit('TOGGLE_DARKMODE', true);
      else commit('TOGGLE_DARKMODE', false);
    },
  },
};
