<script>
export default {
  props: {
    error: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: ""
    }
  }
}
</script>
<template>
  <div class="m-auto">
    <div class="flex flex-wrap items-center">
      <div class="w-full md:w-1/2 px-4 py-16 md:py-0 order-1 md:order-0">
        <div class="max-w-lg ml-auto text-center md:text-left">
          <span
            class="text-3xl lg:text-4xl text-yellow-500 font-bold font-heading"
          >Whoops!</span>
          <h2
            class="mb-2 text-3xl lg:text-4xl dark:text-white font-bold font-heading text-semibold"
          >
            {{ error }}!
          </h2>
          <p
            v-if="description !== undefined"
            class="mb-6 text-blueGray-400 dark:text-white"
          >
            {{ description }}
          </p>
          <div>
            <router-link
              to="/"
              class="block sm:inline-block py-4 px-8 mb-4 sm:mb-0 sm:mr-3 text-xs text-white text-center font-semibold leading-none bg-yellow-500 hover:bg-yellow-600 rounded"
            >
              ← Back
            </router-link>
          </div>
        </div>
      </div>
      <div
        class="flex items-center order-0 md:order-1 w-full md:w-1/2 md:h-full py-12 md:py-0 px-20 md:px-0"
      >
        <img
          class="mx-auto lg:max-w-lg"
          src="@/assets/logo-oracle.png"
          alt=""
        >
      </div>
    </div>
  </div>
</template>