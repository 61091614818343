<script>
export default {
  props: {
    player: {
      type: Object,
      required: true
    },
    avatar: {
      type: String,
      required: false,
      default: () => undefined
    }
  },
  data() {
    return {
      avatarEndpoint: 'https://crafatar.com/'
    }
  }
}
</script>
<template>
  <router-link
    :to="`/oracle/player/${ player.username }/`"
  >
    <img
      v-if="avatar !== undefined"
      class="w-8 h-8 inline-block m-1"
      :src="avatarEndpoint + avatar + player.uniqueId + '?overlay'"
    >
    <p class="inline-block mr-4 hover:underline">
      {{ player.username }}
    </p>
  </router-link>
</template>