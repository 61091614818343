<template>
  <div class="flex flex-col h-screen">
    <!-- Navbar -->
    <nav class="p-4 w-full z-50 mt-2">
      <div class="container mx-auto relative font-black flex justify-between items-center">
        <div class="flex mx-auto items-center">
          <router-link
            v-for="link in links"
            :key="link.path"
            :to="link.path"
            :class="['text-white', $route.path === link.path ? 'bg-amber-500 rounded-md px-4 mx-4 py-2 transition-colors duration-300' : 'hover:bg-amber-500 hover:text-white rounded-md px-4 mx-4 py-2 transition-colors duration-300']"
          >
            {{ link.label }}
          </router-link>
          <a
            href="https://wiki.telosrealms.com/"
            class="text-white hover:bg-amber-500 hover:text-white rounded-md px-4 mx-4 py-2 transition-colors duration-300"
            target="_blank"
          >
            Wiki
          </a>
          <a
            href="https://store.telosrealms.com/"
            class="text-white hover:bg-amber-500 hover:text-white rounded-md px-4 mx-4 py-2 transition-colors duration-300"
            target="_blank"
          >
            Store
          </a>
        </div>
        <a
          href="https://discord.gg/telosmc"
          class="p-1 rounded-lg bg-blurple hover:bg-indigo-400 lg:block"
        >
          <img
            class="w-8 p-2"
            src="./assets/logo-discord.svg"
          >
        </a>
      </div>
    </nav>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentTheme: this.$store.getters.darkMode,
      links: [
        { label: 'Home', path: '/' },
        { label: 'Oracle', path: '/oracle' },
        { label: 'Leaderboards', path: '/leaderboards' }
      ]
    };
  },
  watch: {
    currentTheme: function (currentTheme) {
      let isDarkMode = currentTheme;
      if (isDarkMode) document.querySelector('html').classList.add('dark');
      else document.querySelector('html').classList.remove('dark');
    },
  },
  mounted() {
    if (this.$store.getters.darkMode)
      document.querySelector('html').classList.add('dark');
  },
  methods: {
    toggleDarkMode() {
      var otherMode = !this.currentTheme;
      this.currentTheme = otherMode;
      this.$store.commit('TOGGLE_DARKMODE', otherMode);
    },
  },
};
</script>
