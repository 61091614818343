<script>
import { getById } from '@/glows'
export default {
  props: {
    player: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      avatarEndpoint: 'https://crafatar.com/renders/body/',
    }
  },
  methods: {
    getGlowColor(data) {
      return getById(data.glow).color;
    }
  }
}
</script>
<template>
  <div
    class="rounded-full inline-flex items-center justify-center"
  >
    <img
      v-if="data.glow !== null"
      alt="player"
      class="transform"
      :src="avatarEndpoint + player.id + '?overlay'"
      :style="'filter: drop-shadow(0 0 0.75rem ' + getGlowColor(data) + ');'"
    >
    <img
      v-else
      alt="player"
      class="transform"
      :src="avatarEndpoint + player.id + '?overlay'"
    >
  </div>
  <div
    class="flex flex-col items-center text-center justify-center"
  >
    <h2 class="font-medium title-font mt-4 dark:text-amber-600 text-indigo-800 text-lg">
      {{ player.name }}
    </h2>
    <div class="w-12 h-1 dark:bg-amber-600 bg-indigo-800 rounded mt-2 mb-4" />

    <div class="grid grid-cols-1 grid-rows-1 gap-4 py-2 content-evenly">
      <div
        class="dark:text-white text-base mx-auto my-auto"
      >
        Rank — {{ data.rank }}
      </div>
    </div>
    <div class="grid grid-cols-2 grid-rows-1 gap-4 py-2 content-evenly">
      <div
        class="dark:text-white text-base mx-auto my-auto"
      >
        ❖{{
          Number(data.balance).toLocaleString()
        }}
      </div>
      <div class="has-tooltip text-base">
        <img
          class="mx-auto my-auto"
          :src="`https://cdn.telosrealms.com/img/mastery/${Math.floor(Object.values(data.characters.data.mastery).reduce((acc, val) => acc += val, 0) / 5)}.png`"
        >
        <div class="dark:bg-gray-700 dark:text-gray-300 tooltip rounded shadow-lg p-2 m-2 bg-gray-100 -mt-20">
          Mastery — {{ Math.floor(Object.values(data.characters.data.mastery).reduce((acc, val) => acc += val, 0) / 5) }}
        </div>
      </div>
    </div>
    <p
      v-if="data.server != null"
      class="text-white bg-yellow-600 rounded-lg px-6 py-2 font-extrabold"
    >
      Currently playing on {{ data.server }}
    </p>
  </div>
</template>