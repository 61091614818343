<template>
  <div class="lookup flex-1">
    <div
      v-if="(errors.isError && !loading) || $route.params.name == undefined"
      class="flex h-full"
    >
      <ErrorBanner
        :error="'No profile found for ' + $route.params.name"
        description="We don't think they've joined yet!"
      />
    </div>
    <div
      v-if="!loading && !errors.isError"
      class="about"
    >
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-16 mx-auto flex flex-col">
          <div class="lg:w-5/7">
            <div class="h-full w-full overflow-hidden">
              <Breadcrumbs :player="player" />
            </div>
            <div class="flex flex-col sm:flex-row mt-10">
              <div class="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                <PlayerInfo
                  :player="player"
                  :data="data"
                />
              </div>
              <div
                class="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-indigo-800 dark:border-amber-600 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left"
              >
                <div class="container px-5 mx-auto">
                  <div
                    class="flex flex-wrap -m-4 dark:bg-gray-900 dark:text-gray-200"
                  >
                    <CharactersInfo :characters="data.characters.all" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from 'axios'
import { parse } from 'tinyduration'
import PlayerInfo from '../components/player/info/PlayerInfo.vue'
import CharactersInfo from '../components/player/info/CharactersInfo.vue'
import ErrorBanner from '../components/ErrorBanner.vue'
import { formatDuration, stripKeyFromIdentifier } from '../utils.js'
import Breadcrumbs from '../components/Breadcrumbs.vue'

export default {
  name: 'Oracle',
  components: {
    PlayerInfo,
    CharactersInfo,
    ErrorBanner,
    Breadcrumbs
},
  data() {
    return {
      loading: true,
      errors: [],
      player: [],
      data: [],
      currentTheme: this.$store.getters.darkMode,
    };
  },
  watch: {
    //reload the data with the correct colored fill ins - if this isn't performant, move it to a Compotent and force reload via Compotent Key - must reload somehow due to the color mode fill ins for undefined imgs
    '$store.getters.darkMode': function () {
      this.getUserData();
    },
  },
  created() {
    if (this.$route.params.name != null) this.getUserData();
    else {
      this.loading = false;
      this.errors = 'Not found!';
    }
  },
  methods: {
    updateTitle(name) {
      document.title = name + "'s Profile | Oracle";
    },
    getUserData() {
      axios
        .get(this.$store.getters.getPlayerEndpoint + this.$route.params.name)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.player = response.data.player;
          this.data = response.data.data;

          // Mapping properties from the API response
          this.data.characters.all.map((char) => {
            char['playTime'] = formatDuration(parse(char['playTime']));
            char['type'] = stripKeyFromIdentifier(char['type'])
            return char
          })
          this.data['rank'] = stripKeyFromIdentifier(this.data['rank']).capitalizeFirstLetter()
          if (this.data['currentServer'] !== null) {
            this.data['currentServer'] = stripKeyFromIdentifier(this.data['currentServer']).capitalizeFirstLetter()
          }

          this.loading = false;
          this.updateTitle(this.player.name);
        })
        .catch((e) => {
          this.errors.push(e);
          console.log(e);
          this.errors.isError = true;
          this.loading = false;
        });
    },
  },
};
</script>