<script>
// @ is an alias to /src
import axios from 'axios';
import { Leaderboards, Type, getById } from '@/leaderboards'
import { stripKeyFromIdentifier, formatDuration } from '@/utils';
import ErrorBanner from '@/components/ErrorBanner.vue';
import SoloCharacterLeaderboard from '@/components/leaderboard/SoloCharacterLeaderboard.vue';
import GroupCharacterLeaderboard from '@/components/leaderboard/GroupCharacterLeaderboard.vue';
import GuildLeaderboard from '@/components/leaderboard/GuildLeaderboard.vue';

export default {
  name: 'Leaderboards',
  components: {
    SoloCharacterLeaderboard,
    GroupCharacterLeaderboard,
    GuildLeaderboard,
    ErrorBanner
  },
  data() {
    return {
      loading: true,
      error: undefined,
      entries: [],
      selectedLeaderboard: Object.values(Leaderboards)[0],
      Leaderboards, Type
    };
  },
  created() {
    this.getLeaderboardData();
    document.title = 'Leaderboards | Telos Realms';
  },
  methods: {
    stripKeyFromIdentifier,
    formatDuration,
    getLeaderboardData() {
      this.error = undefined;
      this.entries = [];
      this.loading = true;

      axios
        .get(
          this.$store.getters.getLeaderboardEndpoint + this.selectedLeaderboard.id + '/0'
        )
        .then((response) => {
          this.entries = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.error = 'Could not load leaderboards';
          this.loading = false;
        });
    },
    updateLeaderboard(e) {
      this.selectedLeaderboard = getById(e.target.value);
      this.getLeaderboardData();
    }
  },
};
</script>
<template>
  <div class="flex-1">
    <div
      v-if="error === undefined"
      class="about"
    >
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-16 mx-auto flex flex-col">
          <div class="lg:w-5/7">
            <h3 class="font-sans object-cover p-3 rounded-lg text-center align-middle text-lg text-white font-extrabold dark:bg-amber-600 bg-indigo-800">
              <select
                class="mr-3 font-sans text-center rounded-lg text-white font-extrabold dark:bg-amber-600 bg-indigo-800 max-w-fit"
                @change="updateLeaderboard"
              >
                <option
                  v-for="leaderboard in Leaderboards"
                  :key="leaderboard"
                  :value="leaderboard.id"
                >
                  {{ leaderboard.name }}
                </option>
              </select>
              <span>Leaderboard</span>
            </h3>
            <div
              v-if="!loading && entries.length > 0"
              class="h-full w-full overflow-hidden dark:text-white text-black"
            >
              <div v-if="selectedLeaderboard.type == Type.SOLO_CHARACTER">
                <SoloCharacterLeaderboard :entries="entries" />
              </div>
              <div v-else-if="selectedLeaderboard.type == Type.GROUP_CHARACTER">
                <GroupCharacterLeaderboard :entries="entries" />
              </div>
              <div v-else-if="selectedLeaderboard.type == Type.GUILD">
                <GuildLeaderboard :entries="entries" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      v-else-if="!loading && error !== undefined"
      class="flex h-full" 
    >
      <ErrorBanner
        :error="error"
        description="Please try again later."
      />
    </div>
  </div>
</template>