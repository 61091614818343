import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Oracle from '../views/Oracle.vue';
import Player from '../views/Player.vue';
import Fame from '../views/leaderboards/Fame.vue';
import Graveyard from '../views/Graveyard.vue';
import NotFound from '../views/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/oracle',
    name: 'Oracle',
    component: Oracle,
  },
  {
    path: '/leaderboards',
    name: 'FameLeaderboards',
    component: Fame,
  },
  {
    path: '/oracle/player/:name',
    name: 'Player',
    component: Player,
  },
  {
    path: '/oracle/player/:name/:character',
    name: 'Graveyard',
    component: Graveyard
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
