String.prototype.capitalizeFirstLetter = function() {
  return this.split(' ').reduce((str, word) => str += ' ' + word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(), "");
}

const USERNAME_REGEX = /^\w{3,16}$/;

String.prototype.isValidUsername = function() {
  return USERNAME_REGEX.test(this);
}

export function stripKeyFromIdentifier(identifier) {
  return identifier.split(':')[1];
}

export function formatDuration(duration) {
  let resultTimeString = [];
  if (duration.years !== undefined) {
    resultTimeString.push(
      duration.years + ' year' + (duration.years != 1 ? 's' : '')
    );
  }
  if (duration.months !== undefined) {
    resultTimeString.push(
      duration.months + ' month' + (duration.months != 1 ? 's' : '')
    );
  }
  if (duration.weeks !== undefined) {
    resultTimeString.push(
      duration.weeks + ' week' + (duration.weeks != 1 ? 's' : '')
    );
  }
  if (duration.days !== undefined) {
    resultTimeString.push(
      duration.days + ' day' + (duration.days != 1 ? 's' : '')
    );
  }
  if (duration.hours !== undefined) {
    resultTimeString.push(
      duration.hours + ' hour' + (duration.hours != 1 ? 's' : '')
    );
  }
  if (duration.minutes !== undefined) {
    resultTimeString.push(
      duration.minutes + ' min' + (duration.minutes != 1 ? 's' : '')
    );
  }

  if (resultTimeString.length == 0)
    return "None";

  return resultTimeString.join(' ');
}