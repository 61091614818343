<script>
import HoverableItemIcon from './HoverableItemIcon.vue';

export default {
  components: { HoverableItemIcon },
  props: {
      inventory: {
          type: Object,
          required: true
      }
  }
}
</script>
<template>
  <!-- Main hand -->
  <HoverableItemIcon
    :item="inventory.storage[inventory.selectedSlot]"
  />
  <!-- Offjand -->
  <HoverableItemIcon
    :item="inventory.extra[0]"
  />
  <!-- Armor, boots first -->
  <HoverableItemIcon
    :item="inventory.armor[3]"
  />
  <HoverableItemIcon
    :item="inventory.armor[2]"
  />
  <HoverableItemIcon
    :item="inventory.armor[1]"
  />
  <HoverableItemIcon
    :item="inventory.armor[0]"
  />
</template>