<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      uiEndpoint: 'https://cdn.telosrealms.com/assets/ui/',
      itemEndpoint: 'https://cdn.telosrealms.com/assets/item/',
    }
  }
}
</script>
<template>
  <div class="group inline-block has-tooltip">
    <img
      v-if="item != null && item.type != 'empty'"
      :src="uiEndpoint + item.identifier + '.png'"
      class="tooltip inline-block -ml-20 -mt-10 transform"
      style="--tw-scale-x: 2; --tw-scale-y: 2"
    >
    <img
      v-if="item != null && item.type != 'empty'"
      :src="itemEndpoint + item.identifier + '.png'"
      class="inline-block itemcrop"
    >
  </div>
</template>
<style scoped>
img {
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: pixelated;
}
.itemcrop {
    width: 34px; /* width of container */
    height: 34px; /* height of container */
    object-fit: cover; /* object-fit property */
    object-position: 20% 0%; /* try 20px 10px */
}
</style>
