<script>
import '@/utils'

export default {
	props: {
		entries: {
			type: Object,
			required: true
		}
	},
	data() {
			return {
				avatarEndpoint: "https://crafatar.com/renders/head/"
			};
	},
}
</script>
<template>
  <table
    v-if="entries.length > 0"
    class="table-auto w-10/12 mt-5 m-auto text-center"
  >
    <thead class="font-extrabold text-lg">
      <tr>
        <th />
        <th>
          Name
        </th>
        <th>
          Tier
        </th>
        <th>
          Members
        </th>
        <th>
          Fame
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(entry, index) in entries"
        :key="entry"
      >
        <td class="py-2">
          #{{ index + 1 }}
        </td>
        <td class="py-2">
          {{ entry.guild.name }}
        </td>
        <td class="py-2">
          {{ entry.guild.tier ?? 1 }}
        </td>
        <td class="py-2">
          <img
            v-for="member in Object.keys(entry.guild.members)"
            :key="member"
            class="w-8 h-8 inline-block m-1"
            :src="avatarEndpoint + member + '?overlay'"
          >
        </td>
        <td class="py-2">
          {{ new Number(entry.score).toLocaleString() }}
        </td>
      </tr>
    </tbody>
  </table>
</template>