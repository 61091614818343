<template>
  <div class="home flex-1">
    <div class="flex h-full">
      <div class="m-auto">
        <div class="flex justify-center pb-16">
          <img
            src="@/assets/logo-oracle.png"
            class="mx-auto lg:max-w-lg"
          >
        </div>
        <transition
          name="fade"
          @after-enter="fadeOut"
        >
          <h5
            v-if="error !== undefined"
            class="p-2 rounded-lg text-center font-semibold text-white bg-amber-600 w-48 popup"
          >
            {{ error }}
          </h5>
        </transition>
        <form
          class="w-full mx-auto max-w-sm"
          @submit.prevent="checkPlayer"
        >
          <div
            class="flex items-center border border-gray-200 dark:border-gray-800 rounded-lg shadow-lg p-3"
          >
            <input
              v-model="playerName"
              class="appearance-none bg-transparent border-none w-full text-gray-400 mr-3 py-1 px-2 leading-tight focus:outline-none"
              type="text"
              placeholder="Enter a username..."
              aria-label="Username"
            >
            <input
              class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 py-1 px-2 rounded"
              type="submit"
              value="Find"
            >
          </div>
        </form>
        <div class="pt-4 justify-center">
          <div
            v-for="player in latestLookups"
            :key="player"
            class="flex items-center"
          >
            <img
              :src="craftavatarURL + player.id + '?overlay'"
              class="w-12 h-12 m-1 p-1 shadow-lg rounded-lg block"
            >
            <router-link
              class="block text-gray-700 dark:text-gray-300 hover:animate-spin"
              :to="{ name: 'Player', params: { name: player.name } }"
            >
              {{ player.name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'Oracle',
  data() {
    return {
      latestLookups: [],
      playerName: '',
      craftavatarURL: 'https://crafatar.com/avatars/',
      error: undefined
    };
  },
  created() {
    this.initData();
    document.title = 'Oracle | Telos Realms';
  },
  methods: {
    initData() {
      axios
        .get(this.$store.getters.getEndpoint + '/lookup/recent/')
        .then((response) => {
          // JSON responses are automatically parsed.
          this.latestLookups = response.data;
        })
        .catch((e) => {
          //As long as this exits, it should allow the page to load with no extra delay with the mc server down - dont want the server down to brick the website too
          console.log(e);
        });
    },
    checkPlayer() {
      if (!this.playerName.isValidUsername()) {
        this.error = "Invalid username!"
      } else {
        this.$router.push({
          name: 'Player',
          params: { name: this.playerName },
        });
      }
    },
    fadeOut() {
      let other = this;
      setTimeout(() => { other.error = undefined }, 500)
    }
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.popup {
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-150%);
  margin: auto auto auto auto;
}
</style>