import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import theme from './modules/theme';

const ENDPOINT_URL = 'https://api.telosrealms.com'

export default createStore({
  getters: {
    getLeaderboardEndpoint: (state) => state.leaderboardEndpoint,
    getPlayerEndpoint: (state) => state.playerEndpointUrl,
    getEndpoint: (state) => state.endpointUrl,
    getServerIP: (state) => state.serverIp,
  },
  state: {
    endpointUrl: ENDPOINT_URL,
    leaderboardEndpoint: `${ENDPOINT_URL}/lookup/leaderboard/`,
    playerEndpointUrl: `${ENDPOINT_URL}/lookup/player/`,
    guildEndpointUril: `${ENDPOINT_URL}/lookup/guild/`,
    serverIp: 'telosrealms.com'
  },
  mutations: {},
  actions: {},
  plugins: [createPersistedState()],
  modules: {
    theme,
  },
});
